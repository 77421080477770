<template>
  <v-container>
    <v-dialog
    persistent
    width="500"
    v-model="finished">
    <v-card>
        <v-card-title>
            Quiz beendet
        </v-card-title>
        <v-divider class="mx-4 mb-4"></v-divider>
        <v-card-text>
          <div>Du hast {{ nbCorrectAnswers }} von zehn Fragen richtig beantwortet.
            </div>
          <p v-if="nbCorrectAnswers > 8">
            Wow, du bist ein wahrer Baum-Experte!
          <p v-else-if="nbCorrectAnswers > 5">
            Gar nicht so schlecht. Übung macht den Meister!
          <p v-else>
            Nicht aufgeben. Aller Anfang ist schwer. Probier's doch gleich nochmal und du wirst erste Erfolge sehen.
          </p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            text
            @click="$emit('ButtonAgainClicked')">
            Ich will nochmal!
            </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
  export default {
    name: 'Finisher',
    props: ['finished', 'nbCorrectAnswers'],
    data: () => ({
    }),
  }
</script>