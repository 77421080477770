<template>
  <div>
    <v-img :src="require('../assets/road-1072823_1280.jpg')"
    max-height="200"
    dark
    >
      <v-container>
        <div class="text-lg-h3 text-md-h3 text-sm-h4 text-xs-h4 mt-10">
          Baumquiz
        </div>
        <div class="text-lg-h5 text-sm-h6">
          Los geht's!
        </div>
      </v-container>
    </v-img>
    <v-container>
      <v-row class="text-center">
        <v-col lg="8" md="8" sm="12" xs="12" justify-center>
          <v-layout>
          <v-carousel
            v-bind:cycle="!quizIsFinished"
            interval="10000"
            height="500"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="i in imageOrder"
              :key="i"
              :src="trees[tree].images[i].src"
              :alt="trees[tree].images[i].alt"
              style="min-width: 300px"
            >
            </v-carousel-item>
          </v-carousel>
          </v-layout>
        </v-col>
        <v-col lg="4" md="4" sm="12" xs="12">
          <p>{{ trees[tree].question }}</p>
          <div
            class="my-4"
            v-for="(choice, i) in trees[tree].choices"
            v-bind:key="i"
          >
            <v-btn
              v-if="choice === trees[tree].name"
              :color="buttonColor"
              block
              @click="questionCorrectlyAnswered()"
              >{{ choice }}</v-btn
            >
            <v-btn
              v-else
              :disabled="questionAnswered"
              block
              @click="questionWronglyAnswered()"
              >{{ choice }}</v-btn
            >
          </div>
          <div style="height: 75px">
            <v-btn v-if="questionAnswered" @click="nextQuestion()" block
              >Nächste Frage</v-btn
            >
          </div>
          <v-progress-linear
            :value="progress"
            :buffer-value="100"
            class="mb-10"
          >
          </v-progress-linear>
          <v-badge color="green" :content="positiveGuesses" class="mx-5">
            <v-icon>mdi-thumb-up</v-icon>
          </v-badge>
          <v-badge color="red" :content="negativeGuesses" class="mx-5">
            <v-icon>mdi-thumb-down</v-icon>
          </v-badge>
        </v-col>
      </v-row>
      <Finisher
        v-bind:finished="quizIsFinished"
        v-bind:nbCorrectAnswers="nbCorrectAnswers"
        v-on:ButtonAgainClicked="startQuiz"
      ></Finisher>
    </v-container>
  </div>
</template>

<script>
import Finisher from "./Finisher.vue";
//import Tree from './Tree.vue'
import treesData from "./trees.js";

export default {
  name: "QuizArea",
  components: {
    Finisher,
    //Tree
  },
  data: () => ({
    trees: treesData,
    tree: 0,
    nbCorrectAnswers: 0,
    nbWrongAnswers: 0,
    questionAnswered: false,
    currentIndex: 0,
    color: "grey",
    buttonColor: null,
    treesAsked: [],
  }),
  methods: {
    getRandomTree: function () {
      return Math.floor(Math.random() * this.trees.length);
    },
    nextQuestion: function () {
      this.questionAnswered = false;
      this.currentIndex = 0;
      this.buttonColor = null;

      this.tree = this.getRandomTree();
      while (this.treesAsked.includes(this.tree)) {
        this.tree = this.getRandomTree();
      }
      this.treesAsked.push(this.tree);
    },
    questionCorrectlyAnswered: function () {
      if (this.questionAnswered == 0) {
        this.nbCorrectAnswers += 1;
      }
      this.questionAnswered = true;
      this.buttonColor = "success";
    },
    questionWronglyAnswered: function () {
      if (this.questionAnswered == 0) {
        this.nbWrongAnswers += 1;
      }
      this.questionAnswered = true;
      this.buttonColor = "accent";
    },
    startQuiz: function () {
      this.nbWrongAnswers = 0;
      this.nbCorrectAnswers = 0;
      this.treesAsked = [];
      this.nextQuestion();
    },
  },
  computed: {
    progress: function () {
      //console.log(this.nbCorrectAnswers + this.nbWrongAnswers)
      return (this.nbCorrectAnswers + this.nbWrongAnswers) * 10;
    },
    positiveGuesses: function () {
      if (this.nbCorrectAnswers == 0) return "0";
      else return this.nbCorrectAnswers;
    },
    negativeGuesses: function () {
      if (this.nbWrongAnswers == 0) return "0";
      else return this.nbWrongAnswers;
    },
    quizIsFinished: function () {
      if (this.nbWrongAnswers + this.nbCorrectAnswers > 9) return true;
      else return false;
    },
    imageOrder: function () {
      console.log("imageOrder is called");
      var array = [];
      for (var l = 0; l < this.trees[this.tree].images.length; l++) {
        array.push(l);
      }

      for (var i = this.trees[this.tree].images.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      console.log("imageOrder:");
      console.log(array);
      return array;
    },
  },
  created() {
    this.$eventHub.$on("startQuizEvent", this.startQuiz);
  },
  mounted() {
    this.startQuiz();
  },
  destroyed: function () {
    this.$eventHub.$off("startQuizEvent");
  },
};
</script>
